/* eslint-disable import/prefer-default-export */
import { Link as RouterLink } from 'react-router-dom';
import { HubLayoutConstants } from '../../../globals/hubConstants';
import { featureIsEnabled } from '../../../globals/envSettings';
import { isLtiMode } from '../../../utils/platform';

const getOrgLinks = key => {
  const pathnames = HubLayoutConstants.PATH_NAMES;
  const links = {
    lmsConfigDeployments: {
      link: `${pathnames.ORGANIZATION_PATH}${pathnames.LMS_CONFIG}`,
      matcher: `${pathnames.ORGANIZATION_PATH}${pathnames.LMS_CONFIG}`
    },
    progress: {
      link: `${pathnames.ORGANIZATION_PATH}${pathnames.PROGRESS}`,
      matcher: `${pathnames.ORGANIZATION_PATH}${pathnames.PROGRESS}`
    },
    students: {
      link: `${pathnames.ORGANIZATION_PATH}${pathnames.STUDENTS}`,
      matcher: `${pathnames.ORGANIZATION_PATH}${pathnames.STUDENTS}`
    },
    staff: {
      link: `${pathnames.ORGANIZATION_PATH}${pathnames.STAFF}`,
      matcher: `${pathnames.ORGANIZATION_PATH}${pathnames.STAFF}`
    },
    orgClasses: {
      link: `${pathnames.ORGANIZATION_PATH}${pathnames.CLASSES}`,
      matcher: `${pathnames.ORGANIZATION_PATH}${pathnames.CLASSES}`
    },
    placementTests: {
      link: `${pathnames.ORGANIZATION_PATH}${pathnames.PLACEMENT_TESTS}`,
      matcher: `${pathnames.ORGANIZATION_PATH}${pathnames.PLACEMENT_TESTS}`
    },
    licences: {
      link: `${pathnames.ORGANIZATION_PATH}${pathnames.LICENCES}`,
      matcher: `${pathnames.ORGANIZATION_PATH}${pathnames.LICENCES}`
    }
  };
  return links[key];
};

const getOrgLinksData = ({ content, pathname, isLmsLtiTool = false, currentOrganisationLti = false }) => {
  const linksData = [
    {
      to: getOrgLinks('students').link,
      component: RouterLink,
      textPrimary: content.org_menu_students,
      ariaAttributes: { current: pathname.includes(getOrgLinks('students').matcher) }
    },
    {
      to: getOrgLinks('staff').link,
      component: RouterLink,
      textPrimary: content.org_menu_staff,
      ariaAttributes: { current: pathname.includes(getOrgLinks('staff').matcher) }
    },
    {
      to: getOrgLinks('orgClasses').link,
      component: RouterLink,
      textPrimary: content.org_menu_classes,
      ariaAttributes: { current: pathname === getOrgLinks('orgClasses').matcher }
    },
    {
      to: getOrgLinks('licences').link,
      component: RouterLink,
      textPrimary: content.org_menu_licences,
      ariaAttributes: { current: pathname === getOrgLinks('licences').matcher }
    }
  ];
  if (featureIsEnabled('opt-main-features')) {
    linksData.filter(link => link.to === getOrgLinks('orgClasses').link);
  }
  if (featureIsEnabled('hub-self-service') && isLmsLtiTool && !isLtiMode()) {
    linksData.unshift({
      to: getOrgLinks('lmsConfigDeployments').link,
      component: RouterLink,
      textPrimary: content.org_lms_config_deployments,
      ariaAttributes: { current: pathname === getOrgLinks('lmsConfigDeployments').matcher }
    });
  }
  if (featureIsEnabled('opt-main-features') && !currentOrganisationLti && !isLtiMode()) {
    linksData.unshift({
      to: getOrgLinks('placementTests').link,
      component: RouterLink,
      textPrimary: content.org_menu_placementTests,
      ariaAttributes: { current: pathname === getOrgLinks('placementTests').matcher }
    });
  }

  return linksData;
};

export { getOrgLinksData };
